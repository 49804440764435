



























import { Vue, Component, Prop } from 'vue-property-decorator';

import BannerGroupKindSelect from '@/components/StoreFront/BannerGroup/KindSelect.vue';
import BannerGroupPageCodeSelect from '@/components/StoreFront/BannerGroup/PageCodeSelect.vue';
import { BannerGroupPayload } from '@/models/BannerGroup';
import WithErrors from '@/directives/WithErrors';
import { BannerGroupsMixinTags } from '@/mixins/http/BannerGroupsMixin';
import { RegisterHttp } from '@/utils/Decorators';

@Component({
  components: {
    BannerGroupKindSelect,
    BannerGroupPageCodeSelect
  },
  directives: {
    WithErrors
  }
})
export default class BannerGroupCreateForm extends Vue {
  @Prop({ required: true }) readonly value!: BannerGroupPayload;

  private payload: BannerGroupPayload = { kind: '', pageCode: '', storeFrontId: '' };

  created() {
    if (this.value) {
      this.payload = {...this.value};
    }
  }

  get createTag() {
    return BannerGroupsMixinTags.Create;
  }

  private onKindSelected(kindId: string) {
    this.payload.kind = kindId;
    this.emitPayload();
  }

  private onPageCodeSelected(pageCodeId: string) {
    this.payload.pageCode = pageCodeId;
    this.emitPayload();
  }

  private emitPayload() {
    this.$emit('input', this.payload);
  }
}



















import { Component, Ref, Mixins, Prop } from 'vue-property-decorator';

import VModal from '@/components/VModal.vue';
import BannerGroupCreateForm from '@/components/StoreFront/BannerGroup/CreateForm.vue';
import { BannerGroupPayload } from '@/models/BannerGroup';
import BannerGroupsMixin, { BannerGroupsMixinTags } from '@/mixins/http/BannerGroupsMixin';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';

@Component({
  components: {
    VModal,
    BannerGroupCreateForm
  }
})
export default class BannerGroupCreateModal extends Mixins(BannerGroupsMixin) {
  @Prop({ required: true }) readonly storeFrontId!: string;

  @Ref() readonly modal!: VModal;

  private bannerGroupPayload: BannerGroupPayload = { kind: '', pageCode: '', storeFrontId: '' };

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  private onCancel() {
    this.hide();
  }

  private async onConfirm() {
    this.bannerGroupPayload.storeFrontId = this.storeFrontId;
    const [data, errors] = await easync(this.createBannerGroup(this.bannerGroupPayload, BannerGroupsMixinTags.Create));

    if (errors) {
      showErrorToast('Si è verificato un errore durante la creazione del BanneGroup');
      return;
    }

    showToast('BannerGroup creato!');
    this.$emit('created', data);
    this.hide();
  }
}






























































import { Vue, Component, Prop, Mixins, Ref } from "vue-property-decorator";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import { easync } from "@/utils/http";
import Banner from "@/models/Banner";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import BannerGroupsMixin, { BannerGroupsMixinTags } from "@/mixins/http/BannerGroupsMixin";
import BannerGroup from "@/models/BannerGroup";
import BannerGroupCreateModal from '@/components/StoreFront/BannerGroup/CreateModal.vue';
import EmptyView from '@/components/EmptyView.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { showErrorToast, showToast } from "@/utils/Toast";

@Component({
  components: {
    DataTable,
    BannerGroupCreateModal,
    EmptyView,
    ConfirmModal
  }
})
export default class StoreFrontContentBannerGroupList extends Mixins(BannerGroupsMixin) {
  private bannerGroups: BannerGroup[] = [];

  @Ref() readonly createModal!: BannerGroupCreateModal;
  @Ref() readonly confirmModal!: ConfirmModal;

  @RegisterHttp(BannerGroupsMixinTags.Index) readonly indexRequest!: RequestData;

  created() {
    this.loadBannerGroups();
  }

  get payload() {
    return {
      filter: {
        store_front: this.storeFrontId
      }
    };
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  get columns() {
    return [
      { name: "pageCode", label: "Codice Pagina" },
      { name: "kind", label: "Tipologia" },
      { name: "actions", label: "" }
    ];
  }

  private async loadBannerGroups() {
    const [data, errors] = await easync(this.getBannerGroups(this.payload, BannerGroupsMixinTags.Index));

    if (data) {
      this.bannerGroups = data;
    }
  }

  private onSelected(bannerGroup: BannerGroup) {
    this.$router.push({ name: 'banner_group_show', params: { id: this.storeFrontId, bannerGroupId: bannerGroup.id } });
  }

  private onCreateNewBannerGroup() {
    this.createModal.show();
  }

  private async openConfirmModal(bannerGroup: BannerGroup) {
    const message = "Sei sicuro di voler eliminare questo BannerGroup?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.onDelete(bannerGroup.id);
    }
  }

  private async onDelete(id: string) {
    const [data, errors] = await easync(this.destroyBannerGroup(id, BannerGroupsMixinTags.Destroy + id));

    if (errors) {
      showErrorToast('Si è verificato un errore durante la cancellazione');
      return;
    }

    showToast('BannerGroup eliminato con successso!');
    this.loadBannerGroups();
  }
}
